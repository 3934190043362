/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/apps/common/js/jquery/aacom/plugins/aaTabs.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/legacy/plugins/aa-tabs.js
**/

// ----------------------------------------------------------------------------------------------------------------------------------
// aaTabs.js
//
// Parameters: tabContainer = DOM Object that points to the UL element that holds the LI items that correspond to each content DIV
//			   To skip any of the LIs, assign it the class of 'noTab'
// contentContainer: DIV that contains all content DIVs
//
// The first module will always be the first displayed when the page is loaded
// ----------------------------------------------------------------------------------------------------------------------------------
function aaTabs(tabContainer, contentContainer) {
    // Constant
    this.activeTabClass = "is-current";

    //	Collect tabs; filter out sibling <li> elements you don't want to make into tabs
    this.tabs = jQuery(tabContainer).find("li").not(".noTab");

    //	Collect content modules
    this.content = jQuery(contentContainer).children("div").not(".noTab");

    //	Hide all but the first content module 
    this.content.not(":eq(0)").hide();

    //	Set up click events for tabs
    this.tabs.click(function (e) {
        e.preventDefault();
        var aaTabs = this.parentNode.aaTabs;

        //	Clear active state on all tabs
        aaTabs.tabs.removeClass(aaTabs.activeTabClass);

        //	Hide all content modules
        aaTabs.content.hide();

        //	Make this tab active
        jQuery(this).addClass(aaTabs.activeTabClass);

        //	Show corresponding content module; indices match up to tabs
        //  aaTabs.content.eq(aaTabs.tabs.index(this)).show();

        // LI ID must contain a unique initial part, then the % mark and the exact ID of the target DIV 
        try {
            var ids = this.id.split("%");
            jQuery("#" + ids[1]).show();
        } catch (e) {
            alert("aaTabs error: tab ID doesn't contain % mark: " + this.id);
        }
    });

    // Dynamic attribute created to hold the aaTabs object in the UL element
    tabContainer.aaTabs = this;
}

// ----------------------------------------------------------------------------------------------------------------------------------
// Register with jQuery as a plugin
// ----------------------------------------------------------------------------------------------------------------------------------
jQuery.fn.aaTabs = function (contentContainer) {
    var i = 0;
    var tabs;
    for (i; i < this.length; i++) {
        tabs = new aaTabs(this[i], contentContainer);
    }
};